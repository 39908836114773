import React from 'react'
import './MainTeam.css'

type Names = 'pavlo' | 'nataliia' | 'yehor';

interface MainTeamProps {
    header: string;
    names: Record<Names, {
        name: string;
        description?: string;
    }>;
    images: Record<Names, any>;
}

export const MainTeam = (
    {
        header,
        names: { pavlo, nataliia, yehor },
        images,
    }: MainTeamProps,
) => (
    <section className='main-team main-team-page container'>
        <div className='main-team__header main-team__header-mobile'>
            <h2>{header}</h2>
        </div>
        <div className='main-team__list'>
            <div className='main-team__list-item'>
                <img
                    src={`https://n-design.studio${images.pavlo.desktop.publicUrl}`}
                    alt={pavlo.name}
                />
                <p className='name'>{pavlo.name}</p>
                {pavlo.description && <p className='description'>{pavlo.description}</p>}
            </div>
            <div className='main-team__list-item'>
                <img
                    src={`https://n-design.studio${images.nataliia.desktop.publicUrl}`}
                    alt={nataliia.name}
                />
                <p className='name'>{nataliia.name}</p>
                {nataliia.description && <p className='description'>{nataliia.description}</p>}
            </div>
            <div className='main-team__list-item'>
                <img
                    src={`https://n-design.studio${images.yehor.desktop.publicUrl}`}
                    alt={yehor.name}
                />
                <p className='name'>{yehor.name}</p>
                {yehor.description && <p className='description'>{yehor.description}</p>}
            </div>
        </div>
    </section>
)