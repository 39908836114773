import React from 'react'
import { CircleStudioIcon, NewCircleStudio } from '../../../icons';
import './Service360.css'

interface Service360Props {
    text: string;
    circle: string;
    button: string;
    language: string;
}

export const Service360 = (
    {
        text,
        button,
        circle,
        language
    }: Service360Props
) => (
    <section className='service services-360 container'>
        <div className='service__circle'>
            <div className='service__circle-image'>
                <CircleStudioIcon/>
            </div>
            <div className='service__circle-logo'>
                <NewCircleStudio className='service__circle-logo__image'/>
            </div>
            <div className='service__circle-text'>{circle}</div>
        </div>
        <div className='service__information'>
            <div className='service__information-text'>
                <div dangerouslySetInnerHTML={{ __html: text }}/>

                <a href={`${language === 'ua' ? '/services' : `/${language}/services`}`}>
                    <button className='btn btn-primary service__button'>{button}</button>
                </a>
            </div>
        </div>
    </section>
)
