import React from 'react'
import './Counts.css'

export const Counts = (
    {
        years,
        projects,
        bgImage
    }
) => (
    <section className='counter'>
        <div
            className='counter__image'
            style={{
                backgroundImage: `url('https://n-design.studio${bgImage}')`
            }}
        ></div>
        <div className='counter__list'>
            <div className='counter__list-item'>
                {years.before}
                <p>{years.count}</p>
                {years.after}
            </div>
            <div className='counter__list-item'>
                {projects.before}
                <p>{projects.count}</p>
                {projects.after}
            </div>
        </div>
    </section>
)
