import React, { useState, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { Text } from '../types'
import { MainLayout } from '../components/layouts/Main'
import { SEO } from '../components/ui-components/Seo'
import { Counts } from '../components/ui-components/about/Counts'
import { About } from '../components/ui-components/about/About'
import { Service360 } from '../components/ui-components/about/Service360'
import { MainTeam } from '../components/ui-components/about/MainTeam'
import { PriceForm } from '../components/ui-components/forms'
import { getMainTeamTexts, getTextByKey } from '../utils'
import { WINDOW_MOBILE_WIDTH } from '../common/constants'

const AboutTemplate = (
    {
        pageContext: {
            texts,
            language,
            mediaLinks,
            meta,
            siteImages,
        },
    },
) => {
	const [ windowWidth, setWindowWidth ] = useState(727)

    useEffect(() => {
        setWindowWidth(window.outerWidth)
    }, [])

    const about = useMemo(() => ({
        header: texts.find((t: Text) => t.key === 'about-header').value,
        info: texts.find((t: Text) => t.key === 'about-page-text').value,
        author: texts.find((t: Text) => t.key === 'about-text-author').value
    }), [texts])

    const mainTeam = getMainTeamTexts(texts, siteImages)

    const counts = useMemo(() => ({
        years: {
            before: texts.find((t: Text) => t.key === 'about-info-years-1-text').value,
            count: texts.find((t: Text) => t.key === 'about-info-years-count').value,
            after: texts.find((t: Text) => t.key === 'about-info-years-2-text').value
        },
        projects: {
            before: texts.find((t: Text) => t.key === 'about-info-projects-1-text').value,
            count: texts.find((t: Text) => t.key === 'about-info-projects-count').value,
            after: texts.find((t: Text) => t.key === 'about-info-projects-2-text').value
        }
    }), [texts])

    const service = useMemo(() => ({
        text: texts.find((t: Text) => t.key === 'about-360-text').value,
        button: texts.find((t: Text) => t.key === 'about-360-more-button').value,
        circle: texts.find((t: Text) => t.key === 'about-360-circle-text').value
    }), [texts]);

    const media = useMemo(() => ({
        header: texts.find((t: Text) => t.key === 'about-media-header').value,
        list: mediaLinks
    }), [texts]);

    const seo = useMemo(() => ({
        title: meta[0].title,
        description: meta[0].description,
        keywords: meta[0].keywords,
        lang: meta[0].lang
    }), [texts]);

    const device = useMemo(() => (
        windowWidth > WINDOW_MOBILE_WIDTH ? 'desktop' : 'mobile'
    ), [windowWidth, siteImages]);

    const aboutImage = useMemo(() => (
        siteImages.find(s => s.key === 'about-photo')?.[device].publicUrl
    ), [device, siteImages]);

    const countBgImage = useMemo(() => (
        siteImages.find(s => s.key === 'about-page-info')?.[device].publicUrl
    ), [device, siteImages])

    const priceForm = useMemo(() => ({
        header: getTextByKey(texts, 'contacts-form-header'),
        name: getTextByKey(texts, 'contacts-name-placeholder'),
        phone: getTextByKey(texts, 'contacts-phone-placeholder'),
        email: getTextByKey(texts, 'contacts-email-placeholder'),
        button: getTextByKey(texts, 'contacts-button-more'),
        thanks: getTextByKey(texts, 'contacts-button-thanks')
    }), [getTextByKey, texts])

    return (
        <MainLayout
            urlPrefix='about'
            language={language}
        >
            <Helmet>
                <script>
                    fbq('track', 'ViewContent');
                </script>
                {`<!-- Hotjar Tracking Code for Ndesign -->`}
                <script>
                    {`(function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:3765080,hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
                </script>
            </Helmet>

            <SEO {...seo}/>
            <About
                {...about}
                image={aboutImage}
            />
            <MainTeam {...mainTeam}/>
            <Counts
                years={counts.years}
                projects={counts.projects}
                bgImage={countBgImage}
            />
            <Service360
                {...service}
                language={language}
            />
			<PriceForm form={priceForm}/>
        </MainLayout>
    )
}

export default AboutTemplate