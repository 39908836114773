import React from 'react'
import { AboutProps } from '../../../types'
import './About.css'

export const About = (
    {
        header,
        info,
        author,
        image
    }: AboutProps
) => (
    <section className='about about-page container'>
        <div className='about__header about__header-mobile'>
            <h2>{header}</h2>
        </div>
        <div className='about__description-image'>
            <img
                src={`https://n-design.studio${image}`}
                alt={header}
            />
        </div>
        <div className='about__description-text'>
            <div
                className='info'
                dangerouslySetInnerHTML={{ __html: info }}
            />
            <div className='author'>— {author}</div>
        </div>
    </section>
)
